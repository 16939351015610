<template>
	<settingLayout>
		<section class="wrap_s_block">
			<div class="title_section">
				<h2 class="name_section">Memberships</h2>
			</div>
			<div class="body_ membership_plans">

				<template v-if="(LOADER_GET_ALL_SUBSCRIPTIONS === true || subscriptionLoader) && !SUBSCRIPTION">
					<v-skeleton-loader type="image" class="skeleton_subscription"></v-skeleton-loader>
				</template>
				<div v-if="SUBSCRIPTION && (SUBSCRIPTION.plan === 'founder' || SUBSCRIPTION.plan === 'free')">
					<div class="membership_plan">
						<div class="main_info">
							<div class="name_plan" v-if="SUBSCRIPTION.plan === 'free'">Free</div>
							<div class="name_plan" v-if="SUBSCRIPTION.plan === 'founder'">Founder’s Club</div>

							<div class="description_plan">
								<template v-if="SUBSCRIPTION.plan === 'free'">All the necessities -<br> nothing more</template>
								<template v-if="SUBSCRIPTION.plan === 'founder'">Lifetime access <br>to premium membership</template>
							</div>

							<template v-if="SUBSCRIPTION.plan === 'free'">
								<div class="price_plan">$0/month</div>
								<div class="description_price">Always available</div>
							</template>
							<template v-if="SUBSCRIPTION.plan === 'founder'">
								<div class="founders_image"></div>
							</template>
						</div>
						<button type="button" v-if="SUBSCRIPTION.plan === 'free'" class="c2a_btn level_up" @click="redirectToMemberships" v-ripple>
							Level Up
							<span class="arrow">
                                <span class="line_svg"></span>
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                    <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                </svg>
                            </span>
						</button>
					</div>
				</div>
				<template v-else>
					<div v-for="plan of ALL_SUBSCRIPTION" :key="plan.id">
						<template v-if="LOADER_GET_ALL_SUBSCRIPTIONS === false">
							<div class="membership_plan" v-if="USER_PERMISSIONS" :class="USER_PERMISSIONS">
								<div class="main_info">
									<div class="name_plan">{{ USER_PERMISSIONS }}</div> <!-- Founder’s Club -->
									<div class="description_plan">
										<template v-if="USER_PERMISSIONS === 'premium'">All the necessities <br>plus all the niceties</template>
										<template v-if="USER_PERMISSIONS === 'pro'">All the niceties plus<br> marketing and statistics</template>
									</div>

									<div class="price_plan">
										<template v-if="USER_PERMISSIONS === 'premium'">
											<template v-if="plan.type === 'month'">${{COMMISSION_RATES.user_subscription_month}}/month</template>
											<template v-if="plan.type === 'year'">${{(COMMISSION_RATES.user_subscription_year / 12).toFixed(2)}}/month</template>
										</template>
<!--										<template v-if="USER_PERMISSIONS === 'pro'">$19.99/month</template>-->
									</div>

									<div class="billed">
										<template v-if="USER_PERMISSIONS === 'premium'">Billed {{ plan.type }}ly</template>
									</div>

									<div class="status" :class="plan.status">
										{{ plan.status }}
									</div>

									<div class="description_price" :class="{expires: USER_PERMISSIONS !== 'free'}"><!--  && plan.status === 'canceled' -->
										<template v-if="plan.status === 'canceled'">Expires</template>
										<template v-else>Renews</template>
										{{ plan.currentPeriodEnd | moment('LL') }}
									</div>
								</div>

								<button type="button" v-if="USER_PERMISSIONS === 'premium' || USER_PERMISSIONS === 'pro'" class="secondary_btn manage_plans"
								        @click="redirectToMemberships" v-ripple>Manage
								</button>

							</div>
						</template>
					</div>
				</template>
			</div>
		</section>

		<section class="wrap_s_block">
			<div class="title_section">
				<h2 class="name_section">Accounts</h2>
				<router-link :to="{name: 'accounts'}" class="view_all" v-ripple>View all</router-link>
			</div>
			<div class="body_ membership_plans">
				<template v-if="!!accountsLoading">
					<v-skeleton-loader type="image" class="skeleton_subscription" v-for="index in 5" :key="index"></v-skeleton-loader>
				</template>
				<template v-if="!accountsLoading">
                    <AccountSubscription v-for="account of accounts.items"
                        :key="account.id"
                        :account="account"

                        @renew="renew"
                        @create="create"
                        @cancel="confirmCancel"
                        @un-cancel="unCancel"
                    >
                    </AccountSubscription>
				</template>
				<div v-if="!accountsLoading && !accounts.total" class="wrap_nothing_here_yet">
					<nothingHereYet></nothingHereYet>
				</div>
			</div>
		</section>

        <SwitchAccountSubscription v-if="switchAccount" v-model="switchAccount"></SwitchAccountSubscription>

        <CancelAccountSubscription v-if="cancelAccount" v-model="cancelAccount" @cancel="cancel"></CancelAccountSubscription>

		<paymentStripeModal
	        v-if="paymentArtist"
	        v-model="paymentArtist"
	        :clientSecret="clientSecret"
	        :paymentPerson="paymentArtist"
            @close="loadUserAccounts"
	        @done="loadUserAccounts">
        </paymentStripeModal>

		<errorDialog v-if="errorDialog" v-model="errorDialog"></errorDialog>
	</settingLayout>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import settingLayout from "@/layouts/settingLayout";
import nothingHereYet from "@/components/small/nothingHereYet";
import paymentStripeModal from "@/components/dialogs/paymentStripeModal.vue";
import errorDialog from "@/components/dialogs/error";
import AccountSubscription from "@/components/manage/subscriptions/accountSubscription.vue";
import CancelAccountSubscription from "@/components/dialogs/cancelAccountSubscription"
import SubscriptionManager from "@/services/subscriptionManager";
import type AccountM from "@/models/accountM";

export default {
	name: "subscriptions",
	components: {
        AccountSubscription,
        CancelAccountSubscription,
		settingLayout,
		nothingHereYet,
		paymentStripeModal,
		errorDialog
	},
	data() {
		return {
            // variables
            clientSecret: null,
            accountsLoading: true,
			subscriptionLoader: false,
            paymentArtist: null,
            cancelAccount: null,
            switchAccount: false,
			errorDialog: false,

            // services
            manager: new SubscriptionManager(),
		}
	},
	watch: {
		PROFILE_LOADER() {
			this.subscriptionLoader = false;
			if (this.PROFILE_LOADER === false && this.USER_PERMISSIONS === 'premium') {
				this.loadUserSubscriptions();
			}
		},
	},
	computed: {
		...mapGetters([
			'userID',
			'PROFILE',
			'PROFILE_LOADER',
			'COMMISSION_RATES',
			'SUBSCRIPTION_LINK',
			'SUBSCRIPTION',
			'ALL_SUBSCRIPTION',
			'LOADER_GET_ALL_SUBSCRIPTIONS',
			'LOADER_GET_SUBSCRIPTION',
			'USER_PERMISSIONS',
			'SUBSCRIPTION_CLIENT_SECRET',
		]),
		...mapGetters({
            accounts: 'USER_ACCOUNTS',
		})
	},
	created() {
        if (!this.userID) return;

        // loading user accounts
        this.loadUserAccounts();

        // loading rates for user & accounts
        this.loadCommissionRates();

        // loading user subscriptions
        this.loadUserSubscriptions();
	},
	methods: {
		...mapActions({
			getProfile: 'GET_PROFILE',
            getAccounts: 'GET_USER_ACCOUNTS',
            getSubscription: 'GET_SUBSCRIPTION',
            getAllSubscriptions: 'GET_ALL_SUBSCRIPTIONS',
			loadCommissionRates: 'GET_COMMISSION_RATES',
		}),

        loadUserAccounts() {
            this.getAccounts({owner: this.userID})
                .catch(err => console.error(`subscription, GET_ARTISTS', ${err}`))
                .finally(() => this.accountsLoading = false);
        },

        create(account: AccountM) {
            this.manager.create(account)
                .then((clientSecret) => this.clientSecret = clientSecret)
                .finally(() => {
                    account.changeBusy(false);
                    this.paymentArtist = account;
                });
        },

        renew(account: AccountM) {
            this.manager.renew(account)
                .then((clientSecret) => {
                    this.clientSecret = clientSecret
                })
                .finally(() => {
                    account.changeBusy(false);
                    this.paymentArtist = account;
                });
        },

        cancel(account: AccountM) {
            this.manager.cancel(account)
                .then((response) => {
                    if (response !== true) {
                        this.errorDialog = true;
                        return;
                    }

                    account.subscription.status = 'canceled';
                    account.subscription.canActivate = true;
                    account.subscription.issue.type = 'cancel_expires';
                }).finally(() => this.cancelAccount = null)

        },

        unCancel(account: AccountM) {
            this.manager.unCancel(account)
                .then((response) => {
                    if (response !== true) {
                        this.errorDialog = true;
                        return;
                    }

                    account.subscription.status = 'active';
                    account.subscription.issue = {};
                    account.changeBusy(false);
                })
        },

		loadUserSubscriptions() {
			this.getSubscription()
				.catch(err => console.error(`subscription, GET_SUBSCRIPTION', ${err}`));
			this.getAllSubscriptions()
				.catch(err => console.error(`subscription, GET_ALL_SUBSCRIPTIONS', ${err}`));
		},

		redirectToMemberships() {
			this.$router.push({name: 'memberships'});
		},

		confirmCancel(account: AccountM) {
            this.cancelAccount = account;
		},
	}
}
</script>

<style lang="scss">

</style>
